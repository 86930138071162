.container {
  padding: 0 30px; /* Left/right margins */
  max-width: 1200px;
  margin: 0 auto;
}

.container .title {
  font-weight: bold;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 30px;
  margin-left: -200px;
  margin-right: -200px;
  text-align: center;
  color: #FFBF52;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #2C5D9A;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 0 0 10px 10px;
}

.table {
  width: 100%; 
  border-collapse: collapse;
  margin: 20px 0;
  background-color: #F1E2CF;
}

.table th {
  background-color: #FFBF52;
  color: #1B4D8A;
  padding: 15px;
  text-align: left;
  border-bottom: 3px solid #1B4D8A;
  font-weight: bold;
}

.table td {
  padding: 12px;
  color: #1B4D8A;
  border-bottom: 1px solid #1B4D8A;
}

.table tr:nth-child(even) {
  background-color: rgba(255, 191, 82, 0.1);
}

.table th,
.table td {
  border-right: 1px solid black; /* Adds vertical column lines */
}

.table th:last-child,
.table td:last-child {
  border-right: none; /* Removes the right border from the last column */
}

.form {
  margin-bottom: 30px;
  background-color: rgba(241, 226, 207, 0.9);
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 01);
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.formRow {
  display: flex;
  gap: 20px;
  align-items: flex-start; /* Align items at the top */
  margin-bottom: 200px;
  margin: 0 auto; /* Left/right margins */
  width: 800px;
  justify-content: center;
}

.formRow .label {
  flex: 1;
  margin-bottom: 0; /* Override default margin */
}

.formRow .form {
  flex: 2;
  display: flex;
  gap: 10px;
  margin-bottom: 0; /* Override default margin */
}

/* Adjust input/button inside the inline form */
.formRow .form .input {
  flex: 1;
  margin: 0;
}

.formRow .form .button {
  margin: 0;
  white-space: nowrap;
}

.label {
  display: block;
  margin-bottom: 15px;
  color: #1B4D8A;
  font-weight: bold;
}

.input, select {
  width: 100%;
  min-width: 300px;
  flex: 1 0 auto;
  padding: 12px;
  margin: 8px 0;
  background-color: #FFF;
  border: 2px solid #FFBF52;
  border-radius: 4px;
  color: #1B4D8A;
  font-size: 16px;
}

.formInputGroup {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
}

.wordListContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

/* Adjust existing wordItem style */
.wordItem {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  margin: 0;
}

.image {
  max-width: 120px;
  max-height: 120px;
  object-fit: cover;
  border: 2px solid #FFBF52;
  border-radius: 4px;
}

.button {
  padding: 12px 30px;
  background-color: #FFBF52;
  color: #1B4D8A;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  margin: 10px 30px;
}

.xButton {
  padding: 12px 30px;
  background-color: #8b99af;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  margin: 10px 30px;
}

.button:hover {
  background-color: #1B4D8A;
  color: #FFBF52;
  transform: translateY(-2px);
}

.xButton:hover {
  background-color: #df0737;
  color: white;
  transform: translateY(-2px);
}

.saveButton {
  padding: 12px 30px;
  background-color: #FFBF52;
  color: #1B4D8A;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.saveButton:hover {
  background-color: #df0737;
  color: white;
  transform: translateY(-2px);
}

.clearButton {
  padding: 12px 30px;
  background-color: #8b99af;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.clearButton:hover {
  background-color: #df0737;
  color: white;
  transform: translateY(-2px);
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.error {
  color: #ff6b6b;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

/* New styles for vocabulary sections */
.section-header {
  color: #FFBF52;
  border-left: 4px solid #FFBF52;
  padding-left: 15px;
  margin: 30px 0 20px;
}

.vocabTable {
  margin: 20px 0;
}

.vocabTable th {
  background-color: #1B4D8A;
  color: #FFBF52;
  border: 2px solid #FFBF52;
}

.vocabTable td {
  background-color: rgba(241, 226, 207, 0.9);
  border: 2px solid #1B4D8A;
  font-weight: 500;
}

/* Center all content inside the image column cell */
.imageCell {
  text-align: center;
  vertical-align: middle; /* Ensures vertical centering within the table cell */
}

/* Style adjustments for the prompt input in the image column */
.imageInput {
  width: 200px;      /* Adjust this value as needed to control input width */
  max-width: 100%;   /* Ensure it doesn't overflow its container */
  margin-top: 0.5rem; /* Adds spacing above the input */
}

/* Optionally, if you want the button in the image cell to have top margin */
.imageCell button {
  margin-top: 0.5rem;
}

.native-word {
  background-color: #FFBF52;
  color: #1B4D8A;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 3px;
}

.options-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin: 20px 0;
}

.level-indicator {
  color: #FFBF52;
  font-size: 18px;
  margin-bottom: 15px;
}

.select-group {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
}

.select-group select {
  flex: 1;
  max-width: 250px;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;                 /* Fixed width for consistency across rows */
  margin: 0 auto;
  position: relative;           /* Allows absolute positioning of buttons */
}

.carouselButton {
  width: 40px;                  /* Fixed width for circular shape */
  height: 40px;                 /* Fixed height for circular shape */
  padding: 0;                   /* No padding to maintain shape */
  background-color: #FFF;       /* White background */
  color: #1B4D8A;               /* Dark blue text */
  border: 2px solid #FFBF52;    /* Orange border */
  cursor: pointer;
  border-radius: 50%;           /* Circular shape */
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;           /* Fixed position relative to .carousel */
  top: 50%;                    /* Center vertically */
  transform: translateY(-50%);  /* Adjust for vertical centering */
}

/* Specific positioning for left and right buttons */
.carouselButton:first-child {
  left: 0;                     /* Left button stays on the left edge */
}

.carouselButton:last-child {
  right: 0;                    /* Right button stays on the right edge */
}

.carouselButton:hover {
  background-color: #1B4D8A;
  color: #FFBF52;
  transform: translateY(-50%) translateY(-2px); /* Adjust hover lift */
}

/* Ensure the content stays centered between buttons */
.carousel > div {
  flex: 1;
  text-align: center;
  padding: 0 50px;             /* Space for buttons on both sides */
}