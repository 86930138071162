/* src/Metrics.module.css */

/* Make sure the body has a light background and black text */
html, body {
  margin: 0; /* Remove default margin */
  background-color:#f5f5f5;
  color: #000;
  font-family: Arial, sans-serif;
  height: 100%;
}

/* The main container wrapping everything */
.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* Title at the top */
.title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #27476f; /* or black, or any accent color you want */
}

/* For date range inputs as a row */
.dateRangeContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 1rem;
}

.dateInput {
  padding: 5px 10px;
  border: 1px solid #27476f;
  border-radius: 5px;
  background-color: #fff;
}

/* Timeframe dropdown area */
.timeframeSelector {
  margin-bottom: 20px;
  align-self: flex-end;
}

.timeframeSelector label {
  font-size: 1rem;
  margin-right: 10px;
}

.timeframeSelector select {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #27476f;
  background-color: #ccc;
  color: #000;
}

/* Excluded Users Panel */
.excludedUsers {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: #ffffff;
  border-radius: 8px;
}

.excludedUsers h2 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.excludedList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.excludedListItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.includeButton {
  background-color: #00a300;
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.includeButton:hover {
  background-color: #008f00;
}

.excludeInputContainer {
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
}

.excludeInput {
  flex: 1;
  padding: 5px 10px;
  border: 1px solid #27476f;
  border-radius: 5px;
}

.excludeButton {
  background-color: #f44336; /* red color */
  border: none;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.excludeButton:hover {
  background-color: #d32f2f;
}

/* Note for selected user filter */
.selectedUserNote {
  margin: 1rem 0;
  color: green;
  font-weight: 500;
}

.clearFilterButton {
  background-color: transparent;
  border: none;
  color: #27476f;
  font-size: 0.9rem;
  cursor: pointer;
  margin-left: 0.5rem;
  text-decoration: underline;
}

.clearFilterButton:hover {
  text-decoration: none;
}

/* Chart container */
.chartContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  
}

/* Individual chart card */
.chart {
  flex: 0 0 45%; /* 2 charts per row on wide screens */
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* Link to course charts */
.courseLink {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #ccc;
  text-decoration: none;
  color: #000;
  border-radius: 5px;
  align-self: center;
}

.courseLink:hover {
  background-color: #bbb;
}
