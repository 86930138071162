.container {
    padding-top: 20px;
    font-family: 'Roboto', sans-serif;
    background-color: #1B4D8A;
    color: #FFBF52;
    width: 98%;
    height: 100%;
    margin-left: 1%;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #FFBF52;
  }
  
  .lessonContainer {
    margin-top: 20px;
  }
  
  .lessonRow {
    display: flex;
    flex-direction: column;
    background-color: #F1E2CF;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
  }
  
  .lessonRow select,
  .lessonRow input,
  .lessonRow textarea {
    margin-bottom: 10px;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    background-color: #FFF;
    border: 1px solid #7F8F00;
    border-radius: 4px;
    color: #0f3460;
  }
  
  .form {
    margin-bottom: 20px;
    background-color: #F1E2CF;
    padding: 20px;
    border-radius: 8px;
  }
  
  .formRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .formGroup {
    flex: 1;
    margin-right: 20px;
  }
  
  .label {
    display: block;
    margin-bottom: 10px;
    color: #0f3460;
  }
  
  .input {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    background-color: #FFF;
    border: 1px solid #7F8F00;
    border-radius: 4px;
    color: #0f3460;
  }
  
  .image {
    max-height: 200px;
    width: auto;
  }
  
  .textarea {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    background-color: #FFF;
    border: 1px solid #7F8F00;
    border-radius: 4px;
    color: #0f3460;
  }
  
  .input::placeholder,
  .textarea::placeholder {
    color: #b0b0b0;
  }
  
  .optionsGroup {
    display: flex;
    justify-content: space-between;
  }
  
  .optionsGroup .input {
    flex: 1;
    margin-right: 20px;
  }
  
  .optionsGroup .input:last-child {
    margin-right: 0;
  }
  
  .button {
    padding: 12px 24px;
    background-color: #7F8F00;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .button:hover {
    background-color: #d63447;
  }
  
  .error {
    color: #ff4f5a;
    margin-top: 20px;
    text-align: center;
  }
  
  .backButton {
    background: none;
    border: none;
    color: #FFBF52;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .backButton:hover {
    color: #FFBF52;
  }
  
  .buttonWrapper {
    margin-bottom: 10px;
  }
  
  
  .title {
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .label {
    display: block;
    margin-bottom: 5px;
  }
  
  .input, .textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  
  @media (max-width: 768px) {
    .imageColumn {
      flex: 1 1 calc(50% - 10px);
    }
  }
  
  @media (max-width: 480px) {
    .imageColumn {
      flex: 1 1 100%;
    }
  }
  
  .saveButton {
    align-self: flex-start;
    padding: 8px 16px;
    margin-top: 20px;
  }

  /* New styles for the crop container and button positioning */
.cropContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.cropContainer .reactEasyCrop_Container {
  position: relative;
  width: 100%;
  height: 100%;
}

.cropContainer .button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.imagesGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Three columns */
  gap: 16px; /* Adjust the gap between images as needed */
}

.imageWrapper {
  text-align: center;
}

.image {
  width: auto;
  height: auto;
  object-fit: cover;
}

.descriptionTextarea {
  width: 80%;
  height: 100px; /* Adjust the height for a multiline input */
  margin-top: 8px;
  padding: 8px;
  font-size: 16px; /* Increase font size for readability */
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: vertical; /* Allows the user to resize the textarea vertically */
}

.deleteButton {
  margin-top: 8px;
  background-color: #e94560; /* Style the delete button */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.deleteButton:hover {
  background-color: #d12d48; /* Darker color on hover */
}