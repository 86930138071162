.container {
  padding: 0 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-weight: bold;
  font-size: 30px;
  margin: 0 -200px 30px -200px;
  text-align: center;
  color: #FFBF52;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #2C5D9A;
  padding: 20px 0;
  border-radius: 0 0 10px 10px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  background-color: #F1E2CF;
}

.table th {
  background-color: #FFBF52;
  color: #1B4D8A;
  padding: 15px;
  text-align: left;
  border-bottom: 3px solid #1B4D8A;
  font-weight: bold;
  text-align:center;
}

.table td {
  padding: 12px;
  color: #1B4D8A;
  border-bottom: 1px solid #1B4D8A;
  
}

.table td:first-child {
  text-align: center;
}

.table tr:nth-child(even) {
  background-color: rgba(255, 191, 82, 0.1);
}

.table th,
.table td {
  border-right: 1px solid black; /* Adds vertical column lines */
}

.table th:last-child,
.table td:last-child {
  border-right: none; /* Removes the right border from the last column */
}

.formRow {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.label {
  color: #1B4D8A;
  font-weight: bold;
}

.input, select {
  width: auto;
  min-width: 200px;
  padding: 8px;
  background-color: #FFF;
  border: 2px solid #FFBF52;
  border-radius: 4px;
  color: #1B4D8A;
  font-size: 16px;
}

.button {
  padding: 12px 30px;
  background-color: #FFBF52;
  color: #1B4D8A;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}


.button:hover {
  background-color: #1B4D8A;
  color: #FFBF52;
  transform: translateY(-2px);
}

/* Special styles for modal buttons (inverted hover effect) */
.modalButtons .button {
  background-color: #8b99af;
  color: #1B4D8A;
}

/* Inverted hover effect for modal buttons */
.modalButtons .button:hover {
  background-color: #FFBF52;
  color: #1B4D8A;
}

.modalBackdrop {
  position: fixed;    /* Fixed to viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999;      /* Very high z-index so it sits on top */
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: #FFF;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  z-index: 10000; /* Make sure modal itself is above backdrop */
}


.saveButton {
  padding: 12px 30px;
  background-color: #FFBF52;
  color: #1B4D8A;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.saveButton:hover {
  background-color: #df0737;
  color: white;
  transform: translateY(-2px);
}


.error {
  color: #ff6b6b;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

.questionBlock {
  padding: 10px;
  margin-bottom: 10px;
  background: #F1E2CF;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.modalEdit {
  display: block; /* Ensures each label takes the full width */
  margin-bottom: 20px; /* Adjust spacing between labels */
}


.modalInput{
  width:300px;
  padding: 8px; /* Adjust input padding */
  margin-top: 5px; /* Adds space between label text and input */
}

.image {
  max-width: 120px;
  max-height: 120px;
  object-fit: cover;
  border: 2px solid #FFBF52;
  border-radius: 4px;
}

.imageCell {
  text-align: center;
  vertical-align: middle; /* Ensures vertical centering within the table cell */
}

/* Style adjustments for the prompt input in the image column */
.imageInput {
  width: 200px;      /* Adjust this value as needed to control input width */
  max-width: 100%;   /* Ensure it doesn't overflow its container */
  margin-top: 0.5rem; /* Adds spacing above the input */
}

/* Optionally, if you want the button in the image cell to have top margin */
.imageCell button {
  margin-top: 0.5rem;
}
