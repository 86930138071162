.container {
  padding: 0 30px; /* Left/right margins */
  max-width: 1200px;
  margin: 0 auto;
}

.container .title {
  font-weight: bold;
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 30px;
  margin-left: -200px;
  margin-right: -200px;
  text-align: center;
  color: #FFBF52;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #2C5D9A;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 0 0 10px 10px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  background-color: #F1E2CF;
}

.table th {
  background-color: #FFBF52;
  color: #1B4D8A;
  padding: 15px;
  text-align: left;
  border-bottom: 3px solid #1B4D8A;
  font-weight: bold;
}

.table td:first-child {
  font-weight: bold;
}

.table td {
  padding: 12px;
  color: #1B4D8A;
  border-bottom: 1px solid #1B4D8A;
}

.table tr:nth-child(even) {
  background-color: rgba(255, 191, 82, 0.1);
}

.table th,
.table td {
  border-right: 1px solid black; /* Adds vertical column lines */
}

.table th:last-child,
.table td:last-child {
  border-right: none; /* Removes the right border from the last column */
}

.form {
  margin-bottom: 30px;
  background-color: rgba(241, 226, 207, 0.9);
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 2;
  display: flex;
  flex-wrap: wrap; /* Handle multiple rows when necessary */
  gap: 15px; /* Space between form elements */
  margin-bottom: 30px;
  .button {
    padding: 12px 30px;
    border: none;
    cursor: pointer;
    border-radius: 25px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    margin: 10px 500px 10px 100px;
  }
  .input{
    min-width: 300px;
  }
}

.formRow {
  display: flex;
  align-items: center; /* Align items along a single horizontal line */
  gap: 15px; /* Add spacing between elements */
  margin-bottom: 20px; /* Adjust spacing for separation from other elements */
  
}

.label {
  display: inline-block;
  margin-right: 15px; /* Add spacing between label and input */
  color: #1B4D8A;
  font-weight: bold;
}

.input,
select {
  display: inline-block; /* Inline with the label */
  width: auto; /* Prevent full width */
  min-width: 200px; /* Set a reasonable width */
  padding: 8px; /* Adjust padding for better alignment */
  margin-right: 15px; /* Add spacing between inputs */
  background-color: #FFF;
  border: 2px solid #FFBF52;
  border-radius: 4px;
  color: #1B4D8A;
  font-size: 16px;
}

.wordListContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.wordItem {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  margin: 0;
}

.button {
  padding: 12px 30px;
  background-color: #FFBF52;
  color: #1B4D8A;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  margin: 10px 30px;
}



.xButton {
  padding: 12px 30px;
  background-color: #8b99af;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  margin: 10px 30px;
}

.button:hover {
  background-color: #1B4D8A;
  color: #FFBF52;
  transform: translateY(-2px);
}

.xButton:hover {
  background-color: #df0737;
  color: white;
  transform: translateY(-2px);
}

.saveButton {
  padding: 12px 30px;
  background-color: #FFBF52;
  color: #1B4D8A;
  border: none;
  cursor: pointer;
  border-radius: 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.saveButton:hover {
  background-color: #df0737;
  color: white;
  transform: translateY(-2px);
}

/* Special styles for modal buttons (inverted hover effect) */
.modalButtons .button {
  background-color: #8b99af;
  color: #1B4D8A;
}

/* Inverted hover effect for modal buttons */
.modalButtons .button:hover {
  background-color: #FFBF52;
  color: #1B4D8A;
}

.error {
  color: #ff6b6b;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

.modalEdit {
  display: block; /* Ensures each label takes the full width */
  margin-bottom: 20px; /* Adjust spacing between labels */
}


.modalInput{
  width:300px;
  padding: 8px; /* Adjust input padding */
  margin-top: 5px; /* Adds space between label text and input */
}

.image {
  max-width: 120px;
  max-height: 120px;
  object-fit: cover;
  border: 2px solid #FFBF52;
  border-radius: 4px;
}

.imageCell {
  text-align: center;
  vertical-align: middle; /* Ensures vertical centering within the table cell */
}

/* Style adjustments for the prompt input in the image column */
.imageInput {
  width: 200px;      /* Adjust this value as needed to control input width */
  max-width: 100%;   /* Ensure it doesn't overflow its container */
  margin-top: 0.5rem; /* Adds spacing above the input */
}

/* Optionally, if you want the button in the image cell to have top margin */
.imageCell button {
  margin-top: 0.5rem;
}

