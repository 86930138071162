.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #F5F5F5;
  color: #e0e0e0;
  font-family: 'Roboto', sans-serif;
}

.headerTitle {
  font-size: 32px;
  margin-bottom: 40px;
  color: #1B4D8A;
}

.columns {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.column {
  flex: 1;
  margin: 20px;
  padding: 40px;
  background-color: #7F8F00;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.column:hover {
  background-color: #6f7d01;
}

.title {
  font-size: 28px;
  color: #1B4D8A;
}
