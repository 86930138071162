/* SentenceTranslation.css */

/* Main Container Styles */
.container {
    padding: 0 30px;
    max-width: 1200px;
    margin: 0 auto;
  }
  

  
  /* Title Styles */
  .title {
    font-weight: bold;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 30px;
    margin-left: -200px;
    margin-right: -200px;
    text-align: center;
    color: #FFBF52;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: #2C5D9A;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 0 0 10px 10px;
  }
  
  /* Table Styles */
  .table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background-color: #F1E2CF;
  }
  
  .table th {
    background-color: #FFBF52;
    color: #1B4D8A;
    padding: 15px;
    text-align: left;
    border-bottom: 3px solid #1B4D8A;
    font-weight: bold;
  }
  
  .table td:first-child {
    font-weight: bold;
  }
  
  .table td {
    padding: 12px;
    color: #1B4D8A;
    border-bottom: 1px solid #1B4D8A;
  }
  
  .table tr:nth-child(even) {
    background-color: rgba(255, 191, 82, 0.1);
  }
  
  .table th,
  .table td {
    border-right: 1px solid black;
  }
  
  .table th:last-child,
  .table td:last-child {
    border-right: none;
  }
  
  /* Form Row & Label/Input Styles */
  .formRow {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .label {
    display: inline-block;
    margin-right: 15px;
    color: #1B4D8A;
    font-weight: bold;
  }
  
  .input,
  select {
    display: inline-block;
    width: auto;
    min-width: 200px;
    padding: 8px;
    margin-right: 15px;
    background-color: #FFF;
    border: 2px solid #FFBF52;
    border-radius: 4px;
    color: #1B4D8A;
    font-size: 16px;
  }
  
  /* Word List & Item Styles */
  .wordListContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
  }
  
  .wordItem {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    margin: 0;
  }
  
  /* Button Styles */
  .button {
    padding: 12px 30px;
    background-color: #FFBF52;
    color: #1B4D8A;
    border: none;
    cursor: pointer;
    border-radius: 25px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    margin: 10px 30px;
  }
  
  .button:hover {
    background-color: #1B4D8A;
    color: #FFBF52;
    transform: translateY(-2px);
  }
  
  .xButton {
    padding: 12px 30px;
    background-color: #8b99af;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 25px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    margin: 10px 30px;
  }
  
  .xButton:hover {
    background-color: #df0737;
    color: white;
    transform: translateY(-2px);
  }
  
  .saveButton {
    padding: 12px 30px;
    background-color: #FFBF52;
    color: #1B4D8A;
    border: none;
    cursor: pointer;
    border-radius: 25px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
  }
  
  .saveButton:hover {
    background-color: #df0737;
    color: white;
    transform: translateY(-2px);
  }
  
  /* Modal Specific Styles */
  .modalButtons .button {
    background-color: #8b99af;
    color: #1B4D8A;
  }
  
  .modalButtons .button:hover {
    background-color: #FFBF52;
    color: #1B4D8A;
  }
  
  .modalEdit {
    display: block;
    margin-bottom: 20px;
  }
  
  .modalInput {
    width: 300px;
    padding: 8px;
    margin-top: 5px;
  }
  
  /* Image Styles */
  .image {
    max-width: 120px;
    max-height: 120px;
    object-fit: cover;
    border: 2px solid #FFBF52;
    border-radius: 4px;
  }
  
  .imageCell {
    text-align: center;
    vertical-align: middle;
  }
  
  .imageInput {
    width: 200px;
    max-width: 100%;
    margin-top: 0.5rem;
  }
  
  .imageCell button {
    margin-top: 0.5rem;
  }
  
  /* Error Message */
  .error {
    color: #ff6b6b;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
  }
  
  /* New Custom Classes */
  
  /* Card for each generated sentence */
  .sentenceCard {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px 0;
    background-color: #fff;
  }
  
  /* Container for buttons to ensure consistent spacing and centering */
  .buttonContainer {
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Container for the Save button */
  .saveContainer {
    margin-top: 20px;
    text-align: center;
  }
  